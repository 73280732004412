import { defineNuxtPlugin } from '#app/nuxt'
import { LazyMultiSelect, LazySelectButton, LazyToggleSwitch, LazyTabs, LazyTabList, LazyTab, LazyTabPanels, LazyTabPanel, LazyDialog, LazyFileUpload, LazySkeleton } from '#components'
const lazyGlobalComponents = [
  ["MultiSelect", LazyMultiSelect],
["SelectButton", LazySelectButton],
["ToggleSwitch", LazyToggleSwitch],
["Tabs", LazyTabs],
["TabList", LazyTabList],
["Tab", LazyTab],
["TabPanels", LazyTabPanels],
["TabPanel", LazyTabPanel],
["Dialog", LazyDialog],
["FileUpload", LazyFileUpload],
["Skeleton", LazySkeleton],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
