
import { defineNuxtPlugin, useRuntimeConfig } from '#imports';
import PrimeVue from 'primevue/config';

import BadgeDirective from 'primevue/badgedirective';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import FocusTrap from 'primevue/focustrap';
import AnimateOnScroll from 'primevue/animateonscroll';



export default defineNuxtPlugin(({ vueApp }) => {
  const runtimeConfig = useRuntimeConfig();
  const config = runtimeConfig?.public?.primevue ?? {};
  const { usePrimeVue = true, options = {} } = config;
  const pt = {};
  const theme = {};

  usePrimeVue && vueApp.use(PrimeVue, { ...options, ...pt, ...theme });
  
  vueApp.directive('badge', BadgeDirective);
vueApp.directive('tooltip', Tooltip);
vueApp.directive('ripple', Ripple);
vueApp.directive('styleclass', StyleClass);
vueApp.directive('focustrap', FocusTrap);
vueApp.directive('animateonscroll', AnimateOnScroll);
});
        